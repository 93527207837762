var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"position-relative pb-1"},[(_vm.loading)?_c('div',{staticClass:"loader-overlay"},[_c('b-spinner',{staticStyle:{"width":"4rem","height":"4rem"},attrs:{"variant":"primary"}})],1):_vm._e(),_c('b-card',[_c('form',{ref:"chatForm",staticClass:"mb-3",attrs:{"color":"#7367F0","title":null,"subtitle":null,"shape":"square","finish-button-text":"Submit","back-button-text":"Previous"},on:{"on-complete":_vm.submit}},[_c('validation-observer',{ref:"userInfo",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Name *","label-for":"Name"}},[_c('validation-provider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Name"},model:{value:(_vm.value.name),callback:function ($$v) {_vm.$set(_vm.value, "name", $$v)},expression:"value.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Username *","label-for":"Username"}},[_c('validation-provider',{attrs:{"name":"Username","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Username"},model:{value:(_vm.value.username),callback:function ($$v) {_vm.$set(_vm.value, "username", $$v)},expression:"value.username"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Email *","label-for":"Email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Email","type":"email"},model:{value:(_vm.value.email),callback:function ($$v) {_vm.$set(_vm.value, "email", $$v)},expression:"value.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Age","label-for":"Age"}},[_c('validation-provider',{attrs:{"name":"Age"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Age","type":"number"},model:{value:(_vm.value.age),callback:function ($$v) {_vm.$set(_vm.value, "age", $$v)},expression:"value.age"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Location","label-for":"Location"}},[_c('validation-provider',{attrs:{"name":"Location"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Location"},model:{value:(_vm.value.location),callback:function ($$v) {_vm.$set(_vm.value, "location", $$v)},expression:"value.location"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label-for":"Needs","label":'Needs'}},[_c('validation-provider',{attrs:{"name":'Needs'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:errors.length > 0 ? 'border-danger-for-v-select' : '',attrs:{"id":"Needs","multiple":"","placeholder":'Needs',"label":"name","loading":_vm.topicLoader,"options":_vm.topicsList,"reduce":function (option) { return option._id; },"state":errors.length > 0 ? false : null},model:{value:(_vm.value.topics),callback:function ($$v) {_vm.$set(_vm.value, "topics", $$v)},expression:"value.topics"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label-for":"language","label":'Language'}},[_c('validation-provider',{attrs:{"name":'Language'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:errors.length > 0 ? 'border-danger-for-v-select' : '',attrs:{"id":"language","multiple":"","placeholder":'Language',"label":"name","loading":_vm.languageLoader,"options":_vm.languagesList,"reduce":function (option) { return option._id; },"state":errors.length > 0 ? false : null},model:{value:(_vm.value.languages),callback:function ($$v) {_vm.$set(_vm.value, "languages", $$v)},expression:"value.languages"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Work Life","label-for":"Work Life"}},[_c('validation-provider',{attrs:{"name":"Work Life"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"custom-select-style",class:errors.length > 0 ? 'border-danger-for-v-select' : '',attrs:{"id":"WorkLife","options":_vm.WorkLifeOptions,"placeholder":"Select Work Life","reduce":function (option) { return option.value; },"label":"label"},model:{value:(_vm.value.workLife),callback:function ($$v) {_vm.$set(_vm.value, "workLife", $$v)},expression:"value.workLife"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('b-button',{staticClass:"float-right",attrs:{"id":"show-btn","variant":"primary","disabled":_vm.loading},on:{"click":_vm.submit}},[_vm._v("Save")]),_c('b-button',{staticClass:"float-right mr-1",attrs:{"id":"cancel-btn","variant":"primary"},on:{"click":_vm.close}},[_vm._v("Cancel")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }