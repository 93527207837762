<template>
  <div class="position-relative pb-1">
    <div v-if="loading" class="loader-overlay">
      <b-spinner style="width: 4rem; height: 4rem" variant="primary" />
    </div>
    <b-card>
      <form
        ref="chatForm"
        color="#7367F0"
        :title="null"
        :subtitle="null"
        shape="square"
        finish-button-text="Submit"
        back-button-text="Previous"
        class="mb-3"
        @on-complete="submit"
      >
        <validation-observer ref="userInfo" tag="form">
          <b-row>
            <b-col md="6">
              <b-form-group label="Name *" label-for="Name">
                <validation-provider
                  #default="{ errors }"
                  name="Name"
                  rules="required"
                >
                  <b-form-input
                    v-model="value.name"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group label="Username *" label-for="Username">
                <validation-provider
                  #default="{ errors }"
                  name="Username"
                  rules="required"
                >
                  <b-form-input
                    v-model="value.username"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Username"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group label="Email *" label-for="Email">
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    v-model="value.email"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Email"
                    type="email"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group label="Age" label-for="Age">
                <validation-provider #default="{ errors }" name="Age">
                  <b-form-input
                    v-model="value.age"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Age"
                    type="number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>


            <b-col md="6">
              <b-form-group label="Location" label-for="Location">
                <validation-provider #default="{ errors }" name="Location">
                  <b-form-input
                    v-model="value.location"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Location"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group label-for="Needs" :label="'Needs'">
                <validation-provider #default="{ errors }" :name="'Needs'">
                  <v-select
                    id="Needs"
                    v-model="value.topics"
                    multiple
                    :placeholder="'Needs'"
                    label="name"
                    :loading="topicLoader"
                    :options="topicsList"
                    :reduce="(option) => option._id"
                    :class="
                      errors.length > 0 ? 'border-danger-for-v-select' : ''
                    "
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group label-for="language" :label="'Language'">
                <validation-provider #default="{ errors }" :name="'Language'">
                  <v-select
                    id="language"
                    v-model="value.languages"
                    multiple
                    :placeholder="'Language'"
                    label="name"
                    :loading="languageLoader"
                    :options="languagesList"
                    :reduce="(option) => option._id"
                    :class="
                      errors.length > 0 ? 'border-danger-for-v-select' : ''
                    "
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group label="Work Life" label-for="Work Life">
                <validation-provider #default="{ errors }" name="Work Life">
                  <v-select
                    id="WorkLife"
                    class="custom-select-style"
                    :class="
                      errors.length > 0 ? 'border-danger-for-v-select' : ''
                    "
                    v-model="value.workLife"
                    :options="WorkLifeOptions"
                    placeholder="Select Work Life"
                    :reduce="(option) => option.value"
                    label="label"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

          </b-row>
        </validation-observer>
        <b-button
          id="show-btn"
          @click="submit"
          class="float-right"
          variant="primary"
          :disabled="loading"
          >Save</b-button
        >

        <b-button
          id="cancel-btn"
          @click="close"
          class="float-right mr-1"
          variant="primary"
          >Cancel</b-button
        >
      </form>
    </b-card>
  </div>
</template>
  
  <script>
import { FormWizard, TabContent } from "vue-form-wizard";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import "vue-form-wizard/dist/vue-form-wizard.min.css";

import {
  BFormGroup,
  BFormInput,
  BCard,
  BRow,
  BFormInvalidFeedback,
  BCol,
  BFormTextarea,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BSpinner,
  BFormDatepicker,
  BButton,
} from "bootstrap-vue";
import { required } from "@validations";
import SharedService from "@/services/SharedService.js";
import "vue-phone-number-input/dist/vue-phone-number-input.css";

export default {
  components: {
    BFormDatepicker,
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BFormTextarea,
    BFormInput,
    TabContent,
    vSelect,
    BFormInvalidFeedback,
    BSpinner,
    BCard,
    BInputGroupAppend,
    BFormCheckbox,
  },
  props: ["value", "action"],
  data() {
    return {
      sharedService: new SharedService(),
      loading: false,
      formData: null,
      required,
      languageLoader: true,
      topicLoader: true,
      languagesList: [],
      topicsList: [],
      WorkLifeOptions: [
        { label: "In a job", value: "inAJob" },
        { label: "Parental leave", value: "parentalLeave" },
        { label: "Stay at home parent", value: "stayAtHomeParent" },
        { label: "Student", value: "student" },
      ],
    };
  },
  mounted() {
    this.getLanguagesList();
    this.getTopicsList();
  },
  watch: {},
  methods: {
    submit() {
      this.$refs.userInfo.validate().then((success) => {
        if (success) {
          this.loading = true;
          const formData = new FormData();
          formData.append("name", this.value.name);
          formData.append("username", this.value.username);
          formData.append("email", this.value.email);
          if(this.value.topics !== undefined) formData.append("topicsString", this.value.topics);
          if(this.value.languages !== undefined) formData.append("languagesString", this.value.languages);
          if(this.value.age !== undefined) formData.append("age", this.value.age);
          if(this.value.location !== undefined) formData.append("location", this.value.location);
          if(this.value.workLife !== undefined) formData.append("workLife", this.value.workLife);
          if(this.value._id) formData.append("id", this.value._id);
          if(this.value._id) formData.append("providerType", this.value.providerType)
          this.$emit("formSubmitted", formData);
        } else {
        }
      });
    },
    close() {
      this.$router.push("/all-users");
    },
    getLanguagesList() {
      this.languageLoader = true;
      this.sharedService.getLanguagesList().then((res) => {
        this.languageLoader = false;
        this.languagesList = res;
      });
    },
    getTopicsList() {
      this.topicLoader = true;
      this.sharedService.getTopicsList().then((res) => {
        this.topicLoader = false;
        this.topicsList = res;
      });
    },
    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      this.media = e.target.files[0];
      if (!files.length) return;
      this.createImage(files[0]);
    },
    createImage(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.media1 = e.target.result;
        this.isPreview = true;
      };
      reader.readAsDataURL(file);
    },
  },
};
</script>
<style>
.profile-img {
  width: 140px;
  height: 140px;
  border-radius: 1.357rem;
  object-fit: cover;
  cursor: pointer;
  border: groove;
}
</style>
