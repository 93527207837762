<template>
  <div class="">
    <UserForm ref="UserForm" v-model="formData" @formSubmitted="storeChat"/>
  </div>
</template>

<script>
import UserForm from "@/views/components/user/UserForm";
import UserService from "@/services/UserService";

export default {
  name: "CreateChat",
  components: {
    UserForm
  },
  data() {
    return {
      userService: new UserService(),
      formData: {
        // what key i have
      },
    }
  },
  methods: {
    storeChat(e) {
      this.userService.create(e).then(res => {
        this.$router.push('/all-users')
        // window.location.href = 'home'
      }).catch(err => {
        this.loading = false
      })
    },
  }
}
</script>

<style scoped>
</style>
